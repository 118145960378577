<template>
  <b-card :title="`Tâches non facturées (${totalRows})`">
    <hr />
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group label="Filtrer par :" label-size="sm" label-align-sm="left" label-cols-sm="4"
          label-for="sortBySelect" class="mr-1 mb-md-0">
          <b-input-group size="sm">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions"></b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
              <option :value="false">Croissant</option>
              <option :value="true">Décroissant</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" @click="&quot;&quot;;"
              placeholder="Recherche..." />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Effacer
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table :busy="isBusy" :key="allTasksTable" selected-variant="primary" bordered head-variant="dark" striped
      empty-text="Aucune tâche trouvée" show-empty :per-page="perPage" :current-page="currentPage"
      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
      :filter-included-fields="filterOn" @filtered="onFiltered" responsive class="position-relative"
      :items="allTasksData" :fields="fields">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner small class="align-middle"></b-spinner>
          <strong> Chargement...</strong>
        </div>
      </template>
      <template #cell(customer)="data">
        {{ data.item.customer != null ? data.item.customer.name : "..." }}
        <br />
        <b-badge :variant="data.item.billable == true ? 'primary' : 'warning'" class="mt-1">
          {{ data.item.billable == true ? "Facturable" : "Non facturable" }}
        </b-badge>
      </template>
      <template #cell(master)="data">
        {{ data.item.master != null && data.item.master.firstname && data.item.master.lastname  ? `${data.item.master.firstname} ${data.item.master.lastname} ` : '...'}}
      </template>

      <template #cell(supervisor)="data">
        {{ data.item.supervisor != null && data.item.supervisor.firstname && data.item.supervisor.lastname  ? `${data.item.supervisor.firstname} ${data.item.supervisor.lastname} ` : '...'}}
      </template>
      <template #cell(is_finished)="data" class="task-respo">
        <b-form-checkbox :disabled="
          (data.item.supervisor_id == userInfo.id &&
            data.item.is_finished == 0) ||
          (data.item.user_id != userInfo.id &&
            data.item.supervisor_id != userInfo.id) ||
          (data.item.user_id == userInfo.id && data.item.is_closed == 1)
        " v-model="data.item[getKey(data.item)]" :indeterminate="
          ((!data.item.is_closed && data.item.is_finished) ||
            (data.item.supervisor_id && data.item.is_finished)) == 1
            ? true
            : false
        " class="custom-control-primary" :value="1" @change="_taskChecked(data.item)">
        </b-form-checkbox>
      </template>

      <template #cell(name)="data">
        <div>
          <a :id="'task' + data.item.id">{{ data.item.name }}</a>
          <b-popover :title="data.item.name" :content="data.item.name" placement="top" triggers="hover" variant="dark"
            :target="'task' + data.item.id">
            <div class="row">
              <b-row>
                <b-col cols="auto"><small class="col-12 text-muted text-truncate mb-0 ml-auto">Tâche :</small>
                </b-col>
              </b-row>
              <b-badge v-if="data.item.billable == true">Facturable</b-badge>
              <b-badge variant="warning" v-if="data.item.billable == false">Non facturable</b-badge>
              <div class="m-1 col-12 ml-auto">
                <b-form-checkbox v-model="data.item.billable" class="custom-control-primary"
                  @change="_markBillable(data.item)">Facturable
                </b-form-checkbox>
              </div>
            </div>

            <div class="row">
              <small class="">Description</small>
              <div class="col-12">
                <p class="text-muted">{{ data.item.description }}</p>
              </div>
            </div>
          </b-popover>
        </div>
      </template>
    </b-table>

    <div class="">
      <!-- pagination -->
      <b-row class="row row-cols-2">
        <b-col md="3">
          <b-row>
            <b-col>
              <small>Afficher par page</small>
            </b-col>
            <b-col>
              <b-form-group label="Affichage" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1">
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8" class="d-flex flex-row-reverse">
          <div>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTooltip,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
    vSelect,
    BTooltip,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ToastificationContent,
    ValidationObserver,
  },
  data() {
    return {
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 30],
      isBusy: true,

      thisTableSize: 10,
      filterData: {
        collaboratorId: null,
        supervisorId: null,
        startDate: null,
        endDate: null,
        whoTasks: "all",
        table_size: 10,
        page: 1,
        priority: "null",
      },
      allTasksTable: 0,
      loading: false,

      sortBy: "name",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "is_finished",
          label: "✔",
          thStyle: { width: "1rem" },
          sortable: true,
        },

        { key: "name", label: "Nom", sortable: true },
        { key: "customer", label: "Projet Client", sortable: true },
        {
          key: "start_date",
          label: "Date debut",
          sortable: true,
        },
        {
          key: "end_date",
          label: "Date Fin",
          sortable: true,
        },
        {
          key: "master",
          label: "Responsable",
          thStyle: { width: "2rem" },
          sortable: true,
        },
        {
          key: "supervisor",
          label: "Superviseur",
          thStyle: { width: "2rem" },
          sortable: true,
        },
      ],
    };
  },
  computed: {
    allTasksData() {
      return this.billableTasksNotBilled;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("tasks", ["tasks", "billableTasksNotBilled"]),
    ...mapState("auth", ["userInfo"]),
  },
  mounted() {
    this.allBillableTasksNotBilled().then(() => { this.totalRows = this.billableTasksNotBilled.length; this.isBusy = false });
  },

  methods: {
    getKey(task) {
      if (task.supervisor_id == this.userInfo.id) {
        return "is_closed";
      } else if (task.supervisor_id == null) {
        return "is_closed";
      } else if (task.user_id == this.userInfo.id) {
        return "is_finished";
      }
    },
    getBillable(task) {
      if (task.billable == 1) {
        return true;
      } else {
        return false;
      }
    },
    _taskChecked(task) {
      this.taskChecked({ task: task, userInfo: this.userInfo }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    _markBillable(task) {
      this.taskBillable({ task: task }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    userName(data) {
      return data?.firstname + " " + data?.lastname;
    },
    checkTaskClosed(data) {
      if (data.is_closed == 1) {
        data.is_closed = 0;
        this.closedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche relancée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        data.is_closed = 1;
        this.closedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche clôturée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    checkTaskFinished(data) {
      if (data.is_finished == 1) {
        data.is_finished = 0;
        this.finishedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche relancée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        data.is_finished = 1;
        this.finishedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche terminée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    tasksFilter(data) {
      this.allTasksFilter(data);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    ...mapActions("tasks", [
      "closedTask",
      "finishedTask",
      "taskChecked",
      "taskBillable",
      "allBillableTasksNotBilled",
    ]),
  },
};
</script>
